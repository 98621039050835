import React, {useEffect, useState} from "react";
import {Theme, useTheme} from "@mui/material/styles";
import Text from "../data-display/text";
import {FaPlus} from "react-icons/fa";
import {GrayColor} from "../../constants/constant-ui";
import {Task} from "../../models/task";
import TaskRequestQuote from "./task-request-quote";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import {tasks} from "../../utils/firebase.utils";
import {useParams} from "@reach/router";
import PostTaskWrapper from "../../components/post-task/post-task-wrapper";
import {UserData} from "../../models/user-data";
import {environment} from "../../environments/quickTaskEnvironemt";
import {isQuickTaskProject, project} from "../../utils/constant.urls";

  
const QuikTaskMainColor = environment.mainColor;

const Fade = require("react-reveal/Fade");

const styles = {
  root: {
    padding: "20px",
  },
  paper: {
    padding: "14px 24px",
    //     background: theme.palette.background.paper,
    borderRadius: "6px",
    boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
    border: "1.5px solid rgb(245,245,245)",
    height: "fit-content",
    marginBottom: "20px",
    display: "flex",
  },
  plusButton: {
    background: "#e5e1ff",
    borderRadius: "10px",
    height: "fit-content",
    display: "flex",
    padding: "6px",
    cursor: "pointer",
    marginTop: "5px",
    "& a:hover": {
      background: "red",
    },
  },
  orWrapper: {display: "flex", margin: "24px 5%", alignItems: "center"},
  orDivider: {flex: "1", height: "2px", background: "grey", opacity: "0.1"},
  or: {color: "grey", margin: "0 12px"},
};

const RequestQuote = ({onClose, user}: {onClose: () => void; user: UserData}) => {
  const theme = useTheme();
  const params = useParams();
  const {userData} = useSelector((state: RootState) => state.userData);
  const [userTasks, setTasks] = useState<Task[]>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasResult, setHasResult] = useState(true);

  useEffect(() => {
    (async () => {
      const tasksList = await tasks
        .where("uid", "==", userData.uid)
        .where("isAssigned", "==", false)
        .where("isCancelled", "==", false)
        .where("isComplete", "==", false)
        .where("project", "==", project)
        .where("dateToStart", ">", new Date())
        .orderBy("dateToStart", "desc")
        .get();

      if (!tasksList.empty) {
        let tasks: Task[] = [];
        tasksList.docs.forEach((doc) => {
          let task = doc.data();
          task.id = doc.id;
          tasks.push(new Task(task));
        });
        setTasks(tasks);
      } else {
        setHasResult(false);
      }
      setIsLoading(false);
    })();
  }, [userData.uid]);

  const checkIfInvited = (task: Task): boolean => {
    if (task.invitedTaskers && task.invitedTaskers.length) {
      return task.invitedTaskers.includes(params["id"]);
    }
    return false;
  };
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <Fade bottom distance={"20px"} duration={400} when={!isLoading}>
      <div
        style={{
          ...(styles.root as React.CSSProperties),
        }}
      >
        <div
          style={{
            ...(styles.paper as React.CSSProperties),
            display: "flex",
            cursor: "pointer",
            background: hover ? "lightgray" : "white",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <PostTaskWrapper invitedTasker={params["id"]}>
            <div style={{display: "flex", cursor: "pointer"}}>
              <div
                style={{
                  ...(styles.plusButton as React.CSSProperties),
                  width: "30px",
                  display: "flex",
                }}
              >
                <FaPlus
                  fill={isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main}
                  size={18}
                />
              </div>

              <div style={{width: "15px"}} />

              <Text variant={"body1"} medium>
                Post a New Task
              </Text>
              <br />
            </div>
            <Text
              variant={"subtitle2"}
              style={{
                color: GrayColor,
                whiteSpace: "unset",
                paddingLeft: "40px",
              }}
            >
              Invite taskers to make an offer on a new task
            </Text>
          </PostTaskWrapper>
        </div>

        {hasResult && (
          <>
            <div
              style={{
                ...(styles.orWrapper as React.CSSProperties),
                position: "absolute",
              }}
            >
              <div
                style={{
                  ...(styles.orDivider as React.CSSProperties),
                  position: "absolute",
                }}
              />
              <Text
                variant={"body2"}
                medium
                component={"span"}
                style={{
                  ...(styles.or as React.CSSProperties),
                }}
              >
                or
              </Text>
              <div
                style={{
                  ...(styles.orDivider as React.CSSProperties),
                }}
              />
            </div>
            <div>
              {userTasks &&
                userTasks.length &&
                userTasks.map((task, index) => (
                  <TaskRequestQuote
                    key={`task_${task.id + index}`}
                    task={task}
                    invited={checkIfInvited(task)}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </Fade>
  );
};

export default RequestQuote;
